/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	IAggregate
} from '@shared/interfaces/application-objects/aggregate.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';

/**
 * A class containing static helper methods for commissions.
 *
 * @export
 * @class CommissionsHelper
 */
export class CommissionsHelper
{
	/**
	 * Gets the commission disbursement disbursement methods aggregates array.
	 *
	 * @static
	 * @param {IEntityInstance} entityInstance
	 * The commission disbursement entity instance.
	 * @param {IEntityInstance} ledgerTransactionCommissionType
	 * The ledger transaction commission type.
	 * @returns {number}
	 * The disbursement methods aggregates array which contains the
	 * disbursement method and the commission amount associated.
	 * @memberof CommissionsHelper
	 */
	public static getCommissionDisbursementMethods(
		entityInstance: IEntityInstance,
		ledgerTransactionCommissionType: string = 'Commission'): IAggregate[]
	{
		const disbursements: any =
			entityInstance.data.disbursements;

		const groupedDisbursementMethods: IAggregate[] = Object.values(
			disbursements
				.reduce(
					(aggregate: IAggregate, disbursement: any) =>
					{
						const method: string =
							disbursement.organizationDisbursementMethod;

						const totalAmount: number =	disbursement.commissions
							.filter(
								(commission: any) =>
									commission.ledgerTransactionCommission.type
										=== ledgerTransactionCommissionType)
							.reduce(
								(sum: number, commission: any) =>
									sum + commission
										.ledgerTransactionCommission
										.amount,
								0);

						if(!aggregate[method])
						{
							aggregate[method] =
								{
									key:
										{
											disbursementMethod: method
										},
									value: 0
								};
						}

						aggregate[method].value += totalAmount;

						return aggregate;
					},
					{}));

		return groupedDisbursementMethods;
	}

	/**
	 * Gets the commission disbursement commissions earned amount.
	 *
	 * @static
	 * @param {IEntityInstance} entityInstance
	 * The commission disbursement entity instance.
	 * @returns {number}
	 * The amount of the commissions earned.
	 * @memberof CommissionsHelper
	 */
	public static getCommissionDisbursementEarnedAmount(
		entityInstance: IEntityInstance): number
	{
		const disbursements: any =
			entityInstance.data.disbursements;

		const totalCommissions: number =
			disbursements
				.flatMap(
					(disbursement: any) =>
						disbursement.commissions)
				.map(
					(commission: any) =>
						commission.ledgerTransactionCommission)
				.filter(
					(transactionCommission: any) =>
						this.isCommissionType(transactionCommission))
				.reduce(
					(sum: number, transactionCommission: any) =>
						sum + transactionCommission.amount,
					0);

		return totalCommissions;
	}

	/**
	 * Gets the commission disbursement adjustments amount.
	 *
	 * @static
	 * @param {IEntityInstance} entityInstance
	 * The commission disbursement entity instance.
	 * @returns {number}
	 * The amount of the commissions adjustment.
	 * @memberof CommissionsHelper
	 */
	public static getCommissionDisbursementAdjustmentsAmount(
		entityInstance: IEntityInstance): number
	{
		const disbursements: any =
			entityInstance.data.disbursements;

		const totalAdjustments: number =
			disbursements
				.flatMap(
					(disbursement: any) =>
						disbursement.commissions)
				.map(
					(commission: any) =>
						commission.ledgerTransactionCommission)
				.filter(
					(transactionCommission: any) =>
						this.isAdjustmentType(transactionCommission))
				.reduce(
					(sum: any, transactionCommission: any) =>
						sum + transactionCommission.amount,
					0);

		return totalAdjustments;
	}

	/**
	 * Gets the commission disbursement amount.
	 *
	 * @static
	 * @param {IEntityInstance} entityInstance
	 * The commission disbursement entity instance.
	 * @returns {number}
	 * The amount of the commissions disbursement.
	 * @memberof CommissionsHelper
	 */
	public static getCommissionDisbursementAmount(
		entityInstance: IEntityInstance): number
	{
		return entityInstance.data.total;
	}

	/**
	 * Gets the commissions amount by agency id from the commission
	 * disbursement entity instance.
	 *
	 * @static
	 * @param {IEntityInstance} entityInstance
	 * The commission disbursement entity instance.
	 * @param {number} organizationId
	 * The organization id.
	 * @returns {number}
	 * The commissions amount of the commissions disbursement for the provided
	 * agency id.
	 * @memberof CommissionsHelper
	 */
	public static getCommissionDisbursementCommissionsByAgencyId(
		entityInstance: IEntityInstance,
		organizationId: number): number
	{
		const disbursements: any =
			entityInstance.data.disbursements;

		const disbursement: any =
			disbursements
				.find(
					(item: any) =>
						item.organizationId === organizationId);

		if (AnyHelper.isNull(disbursement))
		{
			return 0;
		}

		const totalCommissions: number =
			disbursement.commissions
				.filter(
					(commission: any) =>
					{
						const transaction: any =
							commission.ledgerTransactionCommission;

						return this.isCommissionType(transaction);
					})
				.reduce(
					(sum: number, commission: any) =>
						sum + commission.ledgerTransactionCommission.amount,
					0);

		return totalCommissions;
	}

	/**
	 * Gets the adjustments amount by agency id from the commission
	 * disbursement entity instance.
	 *
	 * @static
	 * @param {IEntityInstance} entityInstance
	 * The commission disbursement entity instance.
	 * @param {number} organizationId
	 * The organization id.
	 * @returns {number}
	 * The adjustments amount of the commissions disbursement for the provided
	 * agency id.
	 * @memberof CommissionsHelper
	 */
	public static getCommissionDisbursementAdjustmentsByAgencyId(
		entityInstance: IEntityInstance,
		organizationId: number): number
	{
		const disbursements: any =
			entityInstance.data.disbursements;

		const disbursement: any =
			disbursements
				.find(
					(item: any) =>
						item.organizationId === organizationId);

		if (AnyHelper.isNull(disbursement))
		{
			return 0;
		}

		const totalAdjustemnts: number =
			disbursement.commissions
				.filter(
					(commission: any) =>
					{
						const transaction: any =
							commission.ledgerTransactionCommission;

						return this.isAdjustmentType(transaction);
					})
				.reduce(
					(sum: number, commission: any) =>
						sum + commission.ledgerTransactionCommission.amount,
					0);

		return totalAdjustemnts;
	}

	/**
	 * Determines if the the provided ledger transacion commission is identified
	 * as Commission type, based on the type and adjustment type.
	 *
	 * @static
	 * @param {Any} ledgerTransactionCommission
	 * The ledgerTransactionCommission object stored on the commission
	 * disbursement entity instance.
	 * @returns {boolean}
	 * Returns true if is it a Commission, otherwise false.
	 * @memberof CommissionsHelper
	 */
	public static isCommissionType(
		ledgerTransactionCommission: any): boolean
	{
		return (ledgerTransactionCommission.type
			=== InsuranceConstants.commissionTypes.commission
			&& (AnyHelper.isNull(ledgerTransactionCommission.adjustmentType)
				|| ledgerTransactionCommission.adjustmentType
					=== InsuranceConstants.commissionAdjustmentTypes.rollup));
	}

	/**
	 * Determines if the the provided ledger transacion commission is identified
	 * as Adjustment type, based on the type and adjustment type.
	 *
	 * @static
	 * @param {Any} ledgerTransactionCommission
	 * The ledgerTransactionCommission object stored on the commission
	 * disbursement entity instance.
	 * @returns {boolean}
	 * Returns true if is it an Adjustment type, otherwise false.
	 * @memberof CommissionsHelper
	 */
	public static isAdjustmentType(
		ledgerTransactionCommission: any): boolean
	{
		return (ledgerTransactionCommission.type
			=== InsuranceConstants.commissionTypes.commission
			&& (!AnyHelper.isNull(ledgerTransactionCommission.adjustmentType)
				&& ledgerTransactionCommission.adjustmentType
					!== InsuranceConstants.commissionAdjustmentTypes.rollup));
	}

	/**
	 * Determines if the the provided ledger transacion commission is identified
	 * as Direct Commission type, based on the stored metadata.
	 *
	 * @static
	 * @param {Any} ledgerTransactionCommission
	 * The ledgerTransactionCommission object stored on the commission
	 * disbursement entity instance.
	 * @returns {boolean}
	 * Returns true if is it a Direct Commision type, otherwise false.
	 * @memberof CommissionsHelper
	 */
	public static isDirectCommissionType(
		ledgerTransactionCommission: any): boolean
	{
		return ledgerTransactionCommission.metadata.commissionSource
			=== InsuranceConstants.commissionSourceTypes.direct;
	}

	/**
	 * Determines if the the provided ledger transacion commission is identified
	 * as Sub Commission type, based on the stored metadata.
	 *
	 * @static
	 * @param {Any} ledgerTransactionCommission
	 * The ledgerTransactionCommission object stored on the commission
	 * disbursement entity instance.
	 * @returns {boolean}
	 * Returns true if is it a Sub Commision type, otherwise false.
	 * @memberof CommissionsHelper
	 */
	public static isSubCommissionType(
		ledgerTransactionCommission: any): boolean
	{
		return ledgerTransactionCommission.metadata.commissionSource
			=== InsuranceConstants.commissionSourceTypes.sub;
	}
}