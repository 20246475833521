/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	UntypedFormControl
} from '@angular/forms';
import {
	Router
} from '@angular/router';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	ClaimConstants
} from '@claims/constants/claims-constants';
import {
	ClaimsService
} from '@claims/services/claims.service';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	SessionService
} from '@shared/services/session.service';

/* eslint-enable max-len */

@Component({
	selector: 'update-claim-exposure-status-claim',
	templateUrl: './update-claim-exposure-status-claim.component.html'
})

/**
 * A component representing a wizard step for updating
 * a claim exposure status.
 *
 * @export
 * @class UpdateClaimExposureStatusClaimComponent
 * @implements {OnInit}
 * @implements {IDynamicComponent<DynamicWizardComponent, IWizardContext>}
 */
export class UpdateClaimExposureStatusClaimComponent
implements OnInit, IDynamicComponent<DynamicWizardComponent, IWizardContext>
{
	/**
	 * Initializes an instance of the update claim exposure status
	 * claim component.
	 *
	 * @param {Router} router
	 * The router used for navigation and url query parameter storage.
	 * @param {ActivityService} activityService
	 * The activity message service used to notify the user.
	 * @param {ModuleService} moduleService
	 * The module service used to set module changes on entity creation.
	 * @param {EntityService} entityService
	 * The entity service used to lookup entity modules upon creation.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service used in this component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @param {SessionService} sessionService
	 * The session service used in this component.
	 * @param {ClaimsService} claimsService
	 * The claims service used in this component.
	 * @memberof UpdateClaimExposureStatusClaimComponent
	 */
	public constructor(
		public router: Router,
		public activityService: ActivityService,
		public moduleService: ModuleService,
		public entityService: EntityService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public sessionService: SessionService,
		public claimsService: ClaimsService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<
	 * 	DynamicWizardComponent,
	 * 	IWizardContext>}
	 * @memberof UpdateClaimExposureStatusClaimComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets a client message to display if this wizard is not valid
	 * to be completed.
	 *
	 * @type {string}
	 * @memberof UpdateClaimExposureStatusClaimComponent
	 */
	public clientMessage: string = AppConstants.empty;

	/**
	 * Gets or sets the formly layout used in implementing components.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof UpdateClaimExposureStatusClaimComponent
	 */
	public dynamicFormlyLayout: FormlyFieldConfig[];

	/**
	 * Gets or sets the open exposure layout.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof UpdateClaimExposureStatusClaimComponent
	 */
	public openExposureLayout: FormlyFieldConfig[];

	/**
	 * Gets or sets the close exposure layout.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof UpdateClaimExposureStatusClaimComponent
	 */
	public closeExposureLayout: FormlyFieldConfig[];

	/**
	 * Gets or sets the message that will be set if the valid wizard step check
	 * returns false.
	 *
	 * @type {string}
	 * @memberof UpdateClaimExposureStatusClaimComponent
	 */
	private readonly invalidWizardStepMessage: string =
		'The exposure status is not allowed to be updated.';

	/**
	 * Gets or sets the claim id.
	 *
	 * @type {number}
	 * @memberof UpdateClaimExposureStatusClaimComponent
	 */
	private claimId: number;

	/**
	 * Gets or sets the claim instance
	 *
	 * @type {IEntityInstance}
	 * @memberof UpdateClaimExposureStatusClaimComponent
	 */
	private claim: IEntityInstance;

	/**
	 * Gets or sets the update exposure status to string.
	 *
	 * @type {string}
	 * @memberof UpdateClaimExposureStatusClaimComponent
	 */
	private updateStatusTo: string;

	/**
	 * Gets or sets the workflow action name.
	 *
	 * @type {string}
	 * @memberof UpdateClaimExposureStatusClaimComponent
	 */
	private workflowActionName: string;

	/**
	 * Sets the open status string.
	 *
	 * @type {string}
	 * @memberof UpdateClaimExposureStatusClaimComponent
	 */
	private readonly openStatus: string = 'Open';

	/**
	 * Sets the closed status string.
	 *
	 * @type {string}
	 * @memberof UpdateClaimExposureStatusClaimComponent
	 */
	private readonly closedStatus: string = 'Closed';

	/**
	 * Sets the select an option string.
	 *
	 * @type {string}
	 * @memberof UpdateClaimExposureStatusClaimComponent
	 */
	private readonly selectAnOption: string = 'Select an Option';

	/**
	 * Implements the on initialization interface.
	 *
	 * @async
	 * @memberof UpdateClaimExposureStatusClaimComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.claimId =
			this.context.source.activeMenuItem
				.currentData.data.id;

		this.updateStatusTo =
			this.context.source.activeMenuItem
				.currentData.data.updateStatusTo;

		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claims;

		this.claim = await this.entityInstanceApiService.get(this.claimId);

		this.context.source.addToNext(
			this.confirmExposureStatusUpdate.bind(this));

		await this.performPostInitActions();

		this.isWizardStepValidForDisplay(
			this.claim,
			this.updateStatusTo);

		this.context.source.wizardStepLoading = false;
	}

	/**
	 * This will send the update claim exposure status event and navigate to
	 * the claim.
	 *
	 * @async
	 * @memberof UpdateClaimExposureStatusClaimComponent
	 */
	public async confirmExposureStatusUpdate(): Promise<void>
	{
		await this.updateExposureStatus();

		const url: string =
			this.entityInstanceApiService.getBaseUrl()
				+ `/${this.claimId}`;

		await this.entityInstanceApiService.resetAssociatedCache(url);

		await this.navigateToClaim(
			this.claimId,
			ClaimConstants.claimEntityTypeGroups.claims
		);
	}

	/**
	 * Executes the update claim exposure status process by executing the
	 * workflow action to update the claim exposure status.
	 *
	 * @async
	 * @memberof UpdateClaimExposureStatusClaimComponent
	 */
	public async updateExposureStatus(): Promise<void>
	{
		this.workflowActionName =
			this.updateStatusTo === this.openStatus
				? ClaimConstants.workflowActions.reopenClaimExposure
				: ClaimConstants.workflowActions.closeClaimExposure;

		const currentData: any =
			this.context.source.activeMenuItem.currentData.data;

		const queryString: string =
			this.entityInstanceApiService.formUrlParam(
				AppConstants.empty,
				{
					exposureId:currentData.exposureId,
					reason: currentData.reason,
					note: await this.formatNote(
						currentData?.note,
						currentData.reason,
						currentData.exposureId)
				});

		setTimeout(
			() =>
			{
				this.context.source.wizardStepLoading = true;
			});

		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claims;

		return this.activityService.handleActivity(
			new Activity(
				this.entityInstanceApiService.executeAction(
					this.claimId,
					this.workflowActionName,
					null,
					queryString),
				'<strong>Updating Claim Exposure Status</strong>',
				'<strong>Claim Exposure Status Updated</strong>',
				'Claim Exposure status has been updated.',
				'Claim Exposure status has not been updated.'));
	}

	/**
	 * Handles the validity changed event sent from the child dynamic
	 * formly component. This will update the validity of the form for
	 * action buttons.
	 *
	 * @async
	 * @param {boolean} isValid
	 * The validity of the current displayed step data set.
	 * @memberof UpdateClaimExposureStatusClaimComponent
	 */
	public async validityChanged(
		isValid: boolean): Promise<void>
	{
		this.context.source.validStepChanged(isValid);
	}

	/**
	 * Formats the user provided data into a note.
	 *
	 * @async
	 * @param {string} note
	 * The user provided note.
	 * @param {string} reason
	 * The user selected reasons.
	 * @param {string} exposureId
	 * The user selected exposure id.
	 * @returns {string}
	 * The formatted note data.
	 * @memberof UpdateClaimExposureStatusClaimComponent
	 */
	public async formatNote(
		note: string,
		reason: string,
		exposureId: string): Promise<string>
	{
		const reasons: any[] =
			this.updateStatusTo === this.openStatus
				? await this.claimsService.getExposureReasons(
					this.claimId,
					ClaimConstants.reasonType.open)
				: await this.claimsService.getExposureReasons(
					this.claimId,
					ClaimConstants.reasonType.close);

		const reasonData: any =
			reasons.find((item: any) => item.id === reason);

		const exposureData: any =
			this.claim.data.exposures.find(
				(exposure: any) => exposure.resourceIdentifier === exposureId);

		const formattedNote: string =
			note
				? `${exposureData.coverage}`
					+ ` - ${reasonData.description} - ${note}`
				: `${exposureData.coverage}`
					+ ` - ${reasonData.description}`;

		return formattedNote;
	}

	/**
	 * Validates the exposure.
	 *
	 * @async
	 * @return {boolean}
	 * The exposure validity
	 * @memberof UpdateClaimExposureStatusClaimComponent
	 */
	public async validExposure(field: FormlyFieldConfig): Promise<boolean>
	{
		const ledger: IEntityInstance =
			await this.claimsService.getLedger(this.claimId);

		const ledgerTransactions: IEntityInstance[] =
			await this.claimsService.getLedgerTransactions(
				ledger.id,
				`metadata.exposureId eq \"${field.formControl.value}\"`);

		const sum: number =
			this.claimsService.sumLedgerTransactions(ledgerTransactions);

		if (sum !== 0)
		{
			field.asyncValidators.outstandingReservesValidator.message =
				'Oustanding reserves must be zero to close exposure.';
		}

		return Promise.resolve(sum === 0);
	}

	/**
	 * This will navigate to the claim layout using the
	 * claim id provided.
	 *
	 * @async
	 * @param {number} entityId
	 * The claim entity id to navigate.
	 * @param {string} group
	 * The entity group associated to the navigation.
	 * @memberof UpdateClaimExposureStatusClaimComponent
	 */
	private async navigateToClaim(
		entityId: number,
		group: string)
	{
		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		this.router.navigate(
			[
				`${this.moduleService.name}/entities`,
				group,
				AppConstants.viewTypes.edit,
				entityId
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}

	/**
	 * Handles the post initialization action.
	 * This will create the dynamic formly layout for display component creation
	 * and permissions.
	 *
	 * @memberof UpdateClaimExposureStatusClaimComponent
	 */
	private async performPostInitActions(): Promise<void>
	{
		const openExposurePromise: string =
			'return async function(){let options=[]; '
				+ 'var entityInstanceApiService='
				+ 'this.source.resolver.resolveApiService'
				+ '(\"EntityInstanceApiService\");entityInstanceApiService'
				+ '.entityInstanceTypeGroup=\"Claims\";let claim=await '
				+ 'entityInstanceApiService.get(this.source.activeMenuItem'
				+ '.currentData.data.id);const exposures=claim.data.exposures;'
				+ 'const closedExposures=exposures.filter(exposure=>exposure'
				+ '.status===\"Closed\");closedExposures.forEach(exposure=>'
				+ '{options.push({label:exposure.coverage,value:exposure'
				+ '.resourceIdentifier});});return options;}';

		const closeExposurePromise: string =
			'return async function(){let options=[]; '
				+ 'var entityInstanceApiService='
				+ 'this.source.resolver.resolveApiService'
				+ '(\"EntityInstanceApiService\");entityInstanceApiService'
				+ '.entityInstanceTypeGroup=\"Claims\";let claim=await '
				+ 'entityInstanceApiService.get(this.source.activeMenuItem'
				+ '.currentData.data.id);const exposures=claim.data.exposures;'
				+ 'const openExposures=exposures.filter(exposure=>exposure'
				+ '.status===\"Open\");openExposures.forEach(exposure=>'
				+ '{options.push({label:exposure.coverage,value:exposure'
				+ '.resourceIdentifier});});return options;}';

		this.openExposureLayout =
			<FormlyFieldConfig[]>
			[
				<FormlyFieldConfig>
				{
					key: 'data.exposureId',
					type: FormlyConstants.customControls.customDataSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Claim Exposure',
						description: 'Claim exposure to open.',
						placeholder: this.selectAnOption,
						showClear: true,
						dataPromise: openExposurePromise,
						labelTemplate: '${item.label}',
						valueTemplate: '${item.value}',
						required: true,
						disableAutoSetValue: true
					}
				},
				<FormlyFieldConfig>
				{
					key: 'data.reason',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Reason for Reopen',
						description: 'Claim exposure reopen reason.',
						placeholder: this.selectAnOption,
						showClear: true,
						required: true,
						labelTemplate: '${item.description}',
						valueTemplate: '${item.id}',
						options:
							(await this.claimsService
								.getExposureReasons(
									Number(this.claimId),
									ClaimConstants.reasonType.open))
								?.map((item: any) =>
									<IDropdownOption>
									{
										label: item.description,
										value: item.id
									})
					}
				},
				<FormlyFieldConfig>
				{
					key: 'data.note',
					type: FormlyConstants.customControls.customTextArea,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Description',
						description: 'Reopen exposure description.',
						placeholder: 'Reopen notes',
						rows: 1,
						gridColumns: 12
					}
				}
			];

		this.closeExposureLayout =
			<FormlyFieldConfig[]>
			[
				<FormlyFieldConfig>
				{
					key: 'data.exposureId',
					type: FormlyConstants.customControls.customDataSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Claim Exposure',
						description: 'Claim exposure to close.',
						placeholder: this.selectAnOption,
						showClear: true,
						dataPromise: closeExposurePromise,
						labelTemplate: '${item.label}',
						valueTemplate: '${item.value}',
						required: true,
						disableAutoSetValue: true
					},
					asyncValidators: {
						outstandingReservesValidator: {
							expression: (
								_formControl: UntypedFormControl,
								field: FormlyFieldConfig) =>
								this.validExposure(field),
							message:
								AppConstants.empty
						}
					}
				},
				<FormlyFieldConfig>
				{
					key: 'data.reason',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Close Reason',
						description: 'Claim exposure close reason.',
						placeholder: this.selectAnOption,
						showClear: true,
						required: true,
						options:
							(await this.claimsService
								.getExposureReasons(
									Number(this.claimId),
									ClaimConstants.reasonType.close))
								?.map((item: any) =>
									<IDropdownOption>
									{
										label: item.description,
										value: item.id
									})
					}
				},
				<FormlyFieldConfig>
				{
					key: 'data.note',
					type: FormlyConstants.customControls.customTextArea,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Description',
						description: 'Close exposure description.',
						placeholder: 'Closing notes',
						rows: 1,
						gridColumns: 12
					}
				}
			];

		this.dynamicFormlyLayout =
			this.updateStatusTo === this.openStatus
				? this.openExposureLayout
				: this.closeExposureLayout;
	}

	/**
	 * Validates the wizard step based on the component logic to
	 * confirm if this should be displayed or not.
	 *
	 * @param {IEntityInstance} claim
	 * The claim to validate.
	 * @param {IEntityInstance} updateStatusTo
	 * The status the exposure is updated to.
	 * @memberof UpdateClaimExposureStatusClaimComponent
	 */
	private isWizardStepValidForDisplay(
		claim: IEntityInstance,
		updateStatusTo: string): void
	{
		const claimStatus: string =
			claim.data.status;

		const closedExposures: any[] =
			claim.data?.exposures?.filter(
				(exposure: any) =>
					exposure.status === this.closedStatus);

		const openExposures: any[] =
			claim.data?.exposures?.filter(
				(exposure: any) =>
					exposure.status === this.openStatus);

		if (updateStatusTo === this.openStatus)
		{
			this.clientMessage =
				claimStatus === this.openStatus && closedExposures?.length > 0
					? AppConstants.empty
					: this.invalidWizardStepMessage;

			return;
		}

		this.clientMessage =
			claimStatus === this.openStatus && openExposures?.length > 0
				? AppConstants.empty
				: this.invalidWizardStepMessage;
	}
}