/**
 * @copyright WaterStreet. All rights reserved.
*/

import {
	Injectable
} from '@angular/core';
import {
	OperationDefinitionApiService
} from '@api/services/operations/operation-definition.api.service';
import {
	BaseOperationAction
} from '@operation/actions/base/base-operation-action';
import {
	OperationExecutionService
} from '@operation/services/operation-execution.service';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AppCanDeactivateGuard
} from '@shared/guards/app-can-deactivate.guard';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	ActivityService
} from '@shared/services/activity.service';

/**
 * A class representing the action to create a
 * context level drawer.
 *
 * @export
 * @class AddDrawerAction
 * @extends {BaseOperationAction}
 */
@Injectable()
export class AddDrawerAction
	extends BaseOperationAction
{
	/**
	 * Creates an instance of an add drawer action.
	 *
	 * @param {ActivityService} activityService
	 * The activity service used for this action.
	 * @param {OperationExecutionService} operationExecutionService
	 * The operation execution service used for this action.
	 * @param {OperationService} operationService
	 * The operation service used for this action.
	 * @param {OperationDefinitionApiService} operationDefinitionApiService
	 * The operation definition api service used for this action.
	 * @param {AppCanDeactivateGuard} appCanDeactivateGuard
	 * The app can deactivate guard.
	 * @memberof AddDrawerAction
	 */
	public constructor(
		protected activityService: ActivityService,
		protected operationExecutionService: OperationExecutionService,
		protected operationService: OperationService,
		protected operationDefinitionApiService: OperationDefinitionApiService,
		protected appCanDeactivateGuard: AppCanDeactivateGuard)
	{
		super(
			activityService,
			operationExecutionService,
			operationService,
			operationDefinitionApiService,
			appCanDeactivateGuard);
	}

	/**
	 * Gets or sets the operation name.
	 *
	 * @type {string}
	 * @memberof AddDrawerAction
	 */
	public operationName: string =
		'AddDrawer';

	/**
	 * Gets or sets the content component which will be
	 * displayed inside this drawer.
	 *
	 * @type {string}
	 * @memberof AddDrawerAction
	 */
	public contentComponent: string = AppConstants.empty;

	/**
	 * Gets or sets the target component which will identify
	 * the target of this added drawer.
	 *
	 * @type {string}
	 * @memberof AddDrawerAction
	 */
	public targetComponent: string = AppConstants.empty;

	/**
	 * Gets or sets the start open value of this component.
	 * If true this will display the component immediately
	 * on adding the drawer.
	 *
	 * @type {string}
	 * @memberof AddDrawerAction
	 */
	public startOpen: boolean = false;

	/**
	 * Gets the allowed entries for content components for this
	 * action.
	*
	* @type {string}
	* @memberof AddDrawerAction
	*/
	public readonly allowedContentComponents: string[] =
		[
			'BIReportComponent',
			'CommissionsComponent',
			'CommissionsScheduleUpdateComponent',
			'FilesComponent',
			'HistoryComponent',
			'NotesComponent',
			'PaymentBatchComponent',
			'PolicyDetailsComponent',
			'ProductSettingsComponent',
			'RulesComponent',
			'WorkItemsComponent'
		];

	/**
	 * Gets the allowed entries for target components for this
	 * action.
	 *
	 * @type {string[]}
	 * @memberof AddDrawerAction
	 */
	public readonly allowedTargetComponents: string[] =
		[
			'UtilityMenuComponent'
		];

	/**
	 * Gets or sets the unblock interface.
	 *
	 * @type {boolean}
	 * @memberof BaseOperationAction
	 */
	public displayDrawerOverlay: boolean | string = false;

	/**
	 * Gets or sets the unblock interface.
	 *
	 * @type {boolean}
	 * @memberof BaseOperationAction
	 */
	public overlayClassName: string = null;

	/**
	 * Searches the allowed entry arrays against the sent parameters for
	 * this operation. If not valid this will generate and display an
	 * error.
	 *
	 * @memberof AddDrawerAction
	 */
	public validateParameters(): void
	{
		if (this.allowedContentComponents
			.indexOf(this.contentComponent) === -1)
		{
			throw new Error(
				this.getValidationErrorMessage(
					this.contentComponent,
					this.allowedContentComponents));
		}

		if (this.allowedTargetComponents
			.indexOf(this.targetComponent) === -1)
		{
			throw new Error(
				this.getValidationErrorMessage(
					this.targetComponent,
					this.allowedTargetComponents));
		}
	}

	/**
	 * Gets the validation error message displayed when an invalid action
	 * parameter is sent.
	 *
	 * @param {string} comparisonValue
	 * The string value from the parameter to check against the target array
	 * for possible valid entries.
	 * @param {string[]} targetArray
	 * The possible valid entries for the sent comparison value.
	 * @returns {string}
	 * The validation error message to display to the user.
	 * @memberof AddDrawerAction
	 */
	public getValidationErrorMessage(
		comparisonValue: string,
		targetArray: string[]): string
	{
		return `'${comparisonValue}' is not an allowed parameter `
			+ `for the action '${this.operationName}'. `
			+ 'The allowed parameters for this operation are ['
			+ `'${targetArray.join(this.arrayDisplayDelimiter)}'`
			+ ']';
	}

	/**
	 * Executes the defined action.
	 *
	 * @async
	 * @memberof AddDrawerAction
	 */
	public async execute(): Promise<void>
	{
		this.validateParameters();

		const disabledMessage =
			AnyHelper.isNullOrWhitespace(this.disabledMessagePromise)
				? null
				: await StringHelper.transformToDataPromise(
					this.disabledMessagePromise,
					this.pageContext);

		this.promptCanDeactivateGuard =
				!AnyHelper.isNullOrWhitespace(this.displayDrawerOverlay)
					? JSON.parse(<string>this.displayDrawerOverlay)
					: false;

		EventHelper.dispatchAddDrawerEvent(
			this.contentComponent,
			this.targetComponent,
			this.label,
			this.icon,
			this.order,
			this.startOpen,
			AnyHelper.isNullOrWhitespace(this.enabled)
				? false
				: !(await StringHelper.transformAndExecuteBooleanPromise(
					this.enabled,
					this.pageContext)),
			disabledMessage,
			<boolean>this.promptCanDeactivateGuard,
			this.overlayClassName);

		if (!AnyHelper.isNullOrWhitespace(this.badgePromise))
		{
			EventHelper.dispatchSetBadgePromiseEvent(
				this.contentComponent,
				this.targetComponent,
				this.badgePromise);
		}
	}
}