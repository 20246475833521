/* !
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	Router
} from '@angular/router';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	InsuranceService
} from '@insurance/services/insurance.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

/* eslint-enable max-len */

@Component({
	selector: 'adjustment-summary',
	templateUrl: './adjustment-summary.component.html',
	styleUrls: [
		'./adjustment-summary.component.scss'
	]
})

/**
 * A component representing a wizard step to display the commissions summary
 * information.
 *
 * @export
 * @class AdjustmentSummaryComponent
 * @implements {OnInit}
 * @implements {IDynamicComponent<DynamicWizardComponent, IWizardContext>}
 */
export class AdjustmentSummaryComponent
implements OnInit, IDynamicComponent<DynamicWizardComponent, IWizardContext>
{
	/**
	 * Initializes an instance of the adjustment write off component.
	 *
	 * @param {Router} router
	 * The router used for navigation and url query parameter storage.
	 * @param {ActivityService} activityService
	 * The activity message service used to notify the user.
	 * @param {ModuleService} moduleService
	 * The module service used to set module changes.
	 * @param {InsuranceService} insuranceService
	 * The insurance service used to lookup insurance modules.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @param {EntityService} entityService
	 * The entity service api used in this component.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used in this component.
	 * @memberof AdjustmentSummaryComponent
	 */
	public constructor(
		public router: Router,
		public activityService: ActivityService,
		public moduleService: ModuleService,
		public insuranceService: InsuranceService,
		public entityInstanceApiService: EntityInstanceApiService,
		public entityService: EntityService,
		public siteLayoutService: SiteLayoutService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<
	 * 	DynamicWizardComponent,
	 * 	IWizardContext>}
	 * @memberof AdjustmentSummaryComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets the context active menu item current data collected on the
	 * dynamic wizard steps.
	 *
	 * @type {any}
	 * @memberof AdjustmentSummaryComponent
	 */
	public currentData: any;

	/**
	 * Gets or sets the agency id associated to the commission adjustment.
	 *
	 * @type {any}
	 * @memberof AdjustmentSummaryComponent
	 */
	public agencyId: number;

	/**
	 * Gets the workflow action being executed to create the commission
	 * adjustment.
	 *
	 * @type {string}
	 * @memberof AdjustmentSummaryComponent
	 */
	public readonly workflowActionName: string =
		'CreateCommissionAdjustment';

	/**
	 * Implements the on initialization interface.
	 *
	 * @async
	 * @memberof AdjustmentSummaryComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.currentData =
			this.context.source.activeMenuItem.currentData.data;

		this.agencyId =
			this.currentData.id;

		const ledgerEntityInstance: IEntityInstance =
			await this.insuranceService.getAgencyLedger(
				this.currentData.id);

		const ledgerPreviosBalance: number =
			await this.getLedgerPreviousBalance(ledgerEntityInstance.id);

		const adjustmentDetailsData: any =
			{
				priorBalance: ledgerPreviosBalance,
				newBalance:
					ledgerPreviosBalance + this.currentData.amount
			};

		this.currentData =
			{
				...this.currentData,
				adjustmentDetails: adjustmentDetailsData
			};

		this.context.source.addOrUpdateStepData(
			{
				adjustmentDetails: adjustmentDetailsData,
			});

		this.context.source.addToNext(
			this.commissionAdjustment.bind(this));

		this.context.source.validStepChanged(true);

		this.context.source.wizardStepLoading = false;
		this.context.source.changeDetectorReference.detectChanges();
	}

	/**
	 * Identifies if transaction number has been provided.
	 *
	 * @memberof AdjustmentSummaryComponent
	 */
	public hasTransactionNumber(): boolean
	{
		return !AnyHelper.isNullOrEmpty(this.currentData.transactionNumber);
	}

	/**
	 * This will send the commission adjustment event and navigate to the agency
	 * entity instance.
	 *
	 * @async
	 * @memberof AdjustmentSummaryComponent
	 */
	public async commissionAdjustment(): Promise<void>
	{
		const queryString: string =
			`?transactionNumber=${this.currentData.transactionNumber}`
				+ `&amount=${this.currentData.amount}`
				+ `&note=${this.currentData.note}`
				+ `&reason=${this.currentData.reason}`;

		await this.commissionAdjustmentExecution(
			this.agencyId,
			queryString);

		this.navigateToAgencyDashboard(
			this.agencyId);
	}

	/**
	 * Gets the Ledger previous balance.
	 *
	 * @async
	 * @param {number} ledgerId
	 * The ledger previous balance.
	 * @return {number}
	 * The ledger previous balance.
	 * @memberof AdjustmentDetailsComponent
	 */
	private async getLedgerPreviousBalance(
		ledgerId: number): Promise<number>
	{
		const ledgerTransactionInstances: IEntityInstance[] =
			await this.entityService.getWildcardChildren(
				ledgerId,
				InsuranceConstants
					.insuranceEntityTypeGroups
					.ledger,
				InsuranceConstants
					.insuranceEntityTypeGroups
					.ledgerTransactionWildCard,
				'id desc');

		const previousBalance: number =
			ledgerTransactionInstances.length > 0
				? ledgerTransactionInstances[0].data.balance
				: 0;

		return previousBalance;
	}

	/**
	 * Executes the commission adjustment process, which executes the
	 * CreateCommissionAdjustment workflow service with the collected data from
	 * the commission adjustment wizard, then creates the commission ledger
	 * transaction information.
	 *
	 * @async
	 * @param {number} agencyId
	 * The agency id.
	 * @param {string} queryString
	 * The extra parameters sent as query string parameters.
	 * @memberof AdjustmentSummaryComponent
	 */
	private async commissionAdjustmentExecution(
		agencyId: number,
		queryString: string): Promise<void>
	{
		setTimeout(
			() =>
			{
				this.context.source.wizardStepLoading = true;
			});

		await this.activityService.handleActivity(
			new Activity(
				new Promise(
					async(resolve: any, _reject: any): Promise<void> =>
					{
						this.entityInstanceApiService.entityInstanceTypeGroup =
							InsuranceConstants.insuranceEntityTypeGroups
								.agencies;

						await this.entityInstanceApiService
							.executeAction(
								agencyId,
								this.workflowActionName,
								null,
								queryString);

						resolve();
					}),
				'<strong>Processing commission adjustment</strong>',
				'<strong>Commission adjustment processed</strong>',
				'A commission adjustment has been applied to the agency.',
				'A commission adjustment has not been applied to the agency.'));
	}

	/**
	 * This will navigate to the agency dashboard using the provided agency id.
	 *
	 * @param {number} agencyId
	 * The agency id to navigate to.
	 * @memberof AdjustmentSummaryComponent
	 */
	private navigateToAgencyDashboard(
		agencyId: number): void
	{
		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		this.router.navigate(
			[
				`${this.moduleService.name}/entities`,
				InsuranceConstants.insuranceEntityTypeGroups.agencies,
				AppConstants.viewTypes.edit,
				agencyId
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}
}