/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */

import {
	Location
} from '@angular/common';
import {
	Component,
	Input,
	OnInit,
} from '@angular/core';
import {
	Router
} from '@angular/router';
import {
	ISecurityDefinitionDto
} from '@api/interfaces/security/security-definition.dto.interface';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import { DocumentHelper } from '@shared/helpers/document.helper';
import {
	SecurityHelper
} from '@shared/helpers/security.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	ISecurityEntityTypeDefinition
} from '@shared/interfaces/security/security-entity-type-definition.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	SessionService
} from '@shared/services/session.service';
import {
	cloneDeep
} from 'lodash-es';

@Component({
	selector: 'app-commissions-schedule-update',
	templateUrl: './commissions-schedule-update.component.html',
	styleUrls: [
		'./commissions-schedule-update.component.scss'
	]
})

/* eslint-enable max-len */

/**
 * A component representing commission schedule update view.
 *
 * @export
 * @class CommissionsScheduleUpdateComponent
 * @extends {CommissionsScheduleDirective}
 * @implements {IDynamicComponent<Component, any>}
 */
export class CommissionsScheduleUpdateComponent
implements IDynamicComponent<Component, any>, OnInit
{
	/**
	 * Initializes a new instance of the commission schedule update list
	 * component.
	 *
	 * @param {Location} location
	 * The location service.
	 * @param {ActivityService} activityService
	 * The activity service.
	 * @param {EntityService} entityService
	 * The entity service.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service.
	 * @param {SessionService} sessionService
	 * The session service.
	 * @param {Router} router
	 * The router for navigation.

	 * @memberof CommissionsScheduleUpdateComponent
	 */
	public constructor(
		public location: Location,
		public activityService: ActivityService,
		public entityService: EntityService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public sessionService: SessionService,
		public router: Router)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof CommissionsScheduleUpdateComponent
	 */
	@Input() public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the entity instacne to be displayed within the drawer view.
	 *
	 * @type {IEntityInstance}
	 * @memberof CommissionsScheduleUpdateComponent
	 */
	 @Input() public entityInstance: IEntityInstance;

	/**
	 * Gets or sets the security definitions.
	 *
	 * @type {ISecurityEntityTypeDefinition[]}
	 * @memberof CommissionsScheduleUpdateComponent
	 */
	@Input() public securityDefinitions: ISecurityEntityTypeDefinition[];

	/**
	 * Gets or sets a value indicating whether the current display mode is
	 * loading.
	 *
	 * @type {boolean}
	 * @memberof CommissionsScheduleUpdateComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets a value indicating whether the component is saving.
	 *
	 * @type {boolean}
	 * @memberof CommissionsScheduleUpdateComponent
	 */
	public saving: boolean = false;

	/**
	 * Gets or sets the valid saving commissions data.
	 *
	 * @type {boolean}
	 * @memberof CommissionsScheduleUpdateComponent
	 */
	public shouldSave: boolean = false;

	/**
	 * Gets or sets the formly layout used in implementing components.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof CommissionsScheduleUpdateComponent
	 */
	public dynamicFormlyLayout: FormlyFieldConfig[];

	/**
	 * Gets or sets the allowed user access.
	 *
	 * @type {boolean}
	 * @memberof CommissionsScheduleUpdateComponent
	 */
	public allowedUserAccess: boolean = false;

	/**
	 * Gets or sets the policy term commissions data.
	 *
	 * @type {boolean}
	 * @memberof CommissionsScheduleUpdateComponent
	 */
	public commissionsData: { data: any } =
		{ data: { commissions: [] }};

	/**
	 * Gets or sets the initial data displayed in the commissions drawer.
	 *
	 * @type {{ data: any }}
	 * @memberof WorkbenchComponent
	 */
	public archivedCommissionsData: { data: any } =
		{ data: { commissions: [] }};

	/**
	 * Gets or sets the session identifier.
	 *
	 * @type {string}
	 * @memberof CommissionsScheduleUpdateComponent
	 */
	public sessionIdentifier: string = AppConstants.empty;

	/**
	 * Gets or sets the access denied url.
	 *
	 * @type {string}
	 * @memberof CommissionsScheduleUpdateComponent
	 */
	public accessDeniedUrl: string = AppConstants.empty;

	/**
	 * Gets or sets list of required resources.
	 *
	 * @type {string[]}
	 * @memberof CommissionsScheduleUpdateComponent
	 */
	public resources: string[] =
		[
			'Security.AccessPolicyDefinition'
		];

	/**
	 * Gets or sets the client message if insufficient resources exist.
	 *
	 * @type {string}
	 * @memberof CommissionsScheduleUpdateComponent
	 */
	public clientMessage: string =
		'Policy term commissions array access is required.';

	/**
	 * Gets or sets the entity instance component associated to this drawer
	 * component.
	 *
	 * @type {EntityInstanceComponent}
	 * @memberof CommissionsScheduleUpdateComponent
	 */
	public entityInstanceComponent: EntityInstanceComponent;

	/**
	 * Gets the workflow action being executed to update the PolicyTerm
	 * commissions array for corrections.
	 *
	 * @type {string}
	 * @memberof CommissionsScheduleUpdateComponent
	 */
	private readonly workflowActionName: string =
		'CreateCommissionsCorrections';

	/**
	 * Gets or sets the product commission schedules promise array.
	 *
	 * @type {IDropdownOption[]}
	 * @memberof CommissionsScheduleUpdateComponent
	*/
	private productCommissionSchedules: IDropdownOption[];

	/**
	 * Gets or sets an EntityType representing the selected entity type to
	 * update.
	 *
	 * @type {IEntityTypeDto}
	 * @memberof CommissionsScheduleUpdateComponent
	 */
	private entityType: IEntityType;

	/**
	 * Gets or sets the commissions schedules path.
	 *
	 * @type {string}
	 * @memberof CommissionsScheduleUpdateComponent
	 */
	private readonly commissionsSchedulesPath: string =
		'$.data.accounting.commissions';

	/**
	 * Handles the on initialization event.
	 * This will gather and display associated entities based on the given
	 * context parameters.
	 *
	 * @async
	 * @memberof CommissionsScheduleUpdateComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		if (!await this.isPageOwnershipAllowed())
		{
			this.accessDeniedUrl = this.location.path();
			this.sessionIdentifier = this.sessionService.sessionId;
			this.loading = false;

			return;
		}

		this.entityInstanceComponent =
			 <EntityInstanceComponent>this.context.source;

		this.entityInstance =
			this.entityInstanceComponent.entityInstance;

		this.context.data =
			{
				...this.context.data,
				entityInstance: this.entityInstance
			};

		if (AnyHelper.isNullOrEmpty(this.entityType))
		{
			this.entityType =
				await this.entityTypeApiService.getSingleQueryResult(
					`Name eq '${this.entityInstance.entityType}'`, 'Id');
		}

		this.productCommissionSchedules =
			await this.getProductCommissionSchedules();

		await this.setCommissions();

		await this.performPostInitActions();
	}

	/**
	 * Sets the policy term commissions data ordered in reverse mode to
	 *  display fist the agency that created the current policy (Direct).
	 *
	 * @async
	 * @memberof CommissionsScheduleUpdateComponent
	 */
	public async setCommissions(): Promise<void>
	{
		this.commissionsData.data.commissions =
			this.entityInstance
				.data
				.accounting
				.commissions
				.slice()
				.reverse();

		await this.setAgenciesName();

		this.archivedCommissionsData =
			cloneDeep(this.commissionsData);
	}

	/**
	 * Implements the ownership guard interface.
	 * This will calculate drawer ownership permissions.
	 *
	 * @async
	 * @returns {Promise<boolean>}
	 * A value signifying whether or not access is allowed to this drawer.
	 * @memberof CommissionsScheduleUpdateComponent
	 */
	public async isPageOwnershipAllowed(): Promise<boolean>
	{
		const entityInstanceComponent: EntityInstanceComponent =
			<EntityInstanceComponent>this.context.source;

		this.entityInstanceApiService.entityInstanceTypeGroup =
			entityInstanceComponent.entityTypeGroup;

		const userEffectivePermissions: ISecurityDefinitionDto =
			await this.entityInstanceApiService
				.getPermissions(entityInstanceComponent.entityInstance.id);

		this.allowedUserAccess = SecurityHelper
			.getDataSecurityRights(
				this.commissionsSchedulesPath,
				userEffectivePermissions.data)
			.rights
			.update;

		return this.allowedUserAccess;
	}

	/**
	 * Updates the existing entity instance values into the database for
	 * the current commissions schedules in the drawer and creates the
	 * commissions corrections.
	 *
	 * @async
	 * @memberof CommissionsScheduleUpdateComponent
	 */
	public async applyCommissionsCorrections(): Promise<void>
	{
		this.saving = true;

		this.entityInstance
			.data
			.accounting
			.commissions = this.commissionsData.data.commissions;

		for (const commissionSchedule of
			this.entityInstance
				.data
				.accounting
				.commissions)
		{
			delete commissionSchedule.agencyName;
		}

		await this.activityService.handleActivity(
			new Activity(
				new Promise(
					async(resolve: any, _reject: any) =>
					{
						DocumentHelper.setElementDisplay(
							AppConstants.cssClasses.entityLayoutMask,
							true);

						this.entityInstanceApiService.entityInstanceTypeGroup =
							this.entityType.group;

						await this.entityInstanceApiService
							.executeAction(
								this.entityInstance.id,
								this.workflowActionName,
								this.entityInstance);

						resolve();

						await this.entityInstanceComponent.reloadEntity();
					}),
				'<strong>Updating</strong> Commission Schedules',
				'<strong>Updated</strong> Commission Schedules',
				'Commission Schedules was updated.',
				'Commission Schedules has been not updated.'));

		this.saving = false;
	}

	/**
	 * Handles the validity changed event of the contained dynamic form.
	 *
	 * @param {boolean} isValid
	 * The sent validity value of the dynamic form.
	 * @memberof CommissionsScheduleUpdateComponent
	 */
	public validityChanged(
		isValid: boolean): void
	{
		if (this.commissionsData.data.commissions.length === 0)
		{
			this.shouldSave = false;

			return;
		}

		this.shouldSave = isValid;
	}

	/**
	 * Handles the commissions values changed event sent from the child dynamic
	 * formly component. This will notify the component of changes to
	 * data in the formly display.
	 *
	 * @param {boolean} changed
	 * The changed truthy of the current displayed filter set.
	 * @memberof CommissionsScheduleUpdateComponent
	 */
	public commissionsValuesChanged(
		changed: boolean): void
	{
		this.shouldSave = changed;
	}

	/**
	 * Gets product commission schedules associated to the current policy term
	 * instance.
	 *
	 * @async
	 * @return {IDropdownOption[]}
	 * The commission schedules converted to a IDropdownOption array.
	 * @memberof CommissionsScheduleUpdateComponent
	 */
	private async getProductCommissionSchedules(): Promise<IDropdownOption[]>
	{
		const productName: string =
			this.entityInstance.data.productName;

		this.entityInstanceApiService.entityInstanceTypeGroup =
			InsuranceConstants.insuranceEntityTypeGroups.products;

		const productEntityInstance: IEntityInstance =
			await this.entityInstanceApiService.getSingleQueryResult(
				`name eq '${productName}'`, 'Id');

		this.entityInstanceApiService.entityInstanceTypeGroup =
			InsuranceConstants.insuranceEntityTypeGroups.products;

		const commissionSchedules: IEntityInstance[] =
			await this.entityInstanceApiService.getChildren(
				productEntityInstance.id,
				AppConstants.empty,
				`${AppConstants.commonProperties.id} `
					+ AppConstants.sortDirections.ascending,
				null,
				null,
				InsuranceConstants
					.insuranceEntityTypeGroups
					.commissionsSchedules);

		return commissionSchedules.map(
			(commissionSchedule: IEntityInstance) =>
				<IDropdownOption>
				{
					label: commissionSchedule.data.name,
					value: commissionSchedule.data.name
				});
	}

	/**
	 * Sets the agency name to each commission schedules associated to the
	 * current policy term instance.
	 *
	 * @async
	 * @memberof CommissionsScheduleUpdateComponent
	 */
	private async setAgenciesName(): Promise<void>
	{
		const promiseArray: Promise<any>[] = [];

		for (const commissionSchedule of this.commissionsData.data.commissions)
		{
			promiseArray.push(
				(async () =>
				{
					this.entityInstanceApiService.entityInstanceTypeGroup =
						InsuranceConstants
							.insuranceEntityTypeGroups
							.agencies;

					const agencyEntityInstance: IEntityInstance =
						await this.entityInstanceApiService
							.getSingleQueryResult(
								'resourceIdentifier eq '
									+ `'${commissionSchedule.agencyId}'`,
								'Id');

					commissionSchedule.agencyName =
						agencyEntityInstance.data?.name?.legalName;
				})());
		}

		await Promise.all(promiseArray);
	}

	/**
	 * Determines if the current policy term instance is active.
	 *
	 * @memberof CommissionsScheduleUpdateComponent
	 */
	private isPolicyTermActive(): boolean
	{
		return this.entityInstance.data.status ===
			InsuranceConstants.policyTermStatusTypes.active;
	}

	/**
	 * Handles the post initialization action.
	 * This will create the dynamic formly layout for display component creation
	 * and permissions.
	 *
	 * @async
	 * @memberof CommissionsScheduleUpdateComponent
	 */
	private async performPostInitActions(): Promise<void>
	{
		this.dynamicFormlyLayout =
			<FormlyFieldConfig[]>
			[
				<FormlyFieldConfig>
				{
					key: 'data.commissions',
					type: FormlyConstants
						.customControls.customRepeater,
					defaultValue: [],
					props: {
						label: 'Commission Schedules',
						hideSingularIdentifier: true,
						titleFormat: '{agencyName}',
						disabled: !this.isPolicyTermActive(),
						filter: AppConstants.empty,
						initialItemValue: {}
					},
					fieldArray: {
						fieldGroup: [
							<FormlyFieldConfig>
							{
								key: 'scheduleName',
								type:
									FormlyConstants
										.customControls
										.customSelect,
								wrappers: [
									FormlyConstants
										.customControls
										.customFieldWrapper
								],
								props: {
									placeholder: 'Select an Option',
									enabled: true,
									options: this.productCommissionSchedules
								},
								expressions: {
									'props.label':
										(field: FormlyFieldConfig) =>
											AnyHelper.isNullOrWhitespace(
												field.model.scheduleType)
												? 'Commision Schedule'
												: field.model.scheduleType
													+ ' Commission Schedule'
								}
							},
							<FormlyFieldConfig>
							{
								key: 'agencyName',
								type:
									FormlyConstants
										.customControls
										.input,
								wrappers: [
									FormlyConstants
										.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Agency Name'
								},
								expressions: {
									hide: 'true'
								}
							}
						]
					}
				}
			];

		this.loading = false;
	}
}